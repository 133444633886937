import React, {useState} from 'react'
import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css'
import TaskModalContainer from '../containers/TaskModalContainer'
import Select from "react-select";
import {Col, Form, OverlayTrigger, Row} from "react-bootstrap";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";
import TaskListItem from "./TaskListItem";
import QuickTaskForm from "./QuickTaskForm";
import moment from "moment/moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TaskDashboardBody(props) {

  const initialState = {
    open_important: true,
    open_past_due: true,
    open_today: true,
    open_tomorrow: true,
    open_this_week: true,
    open_future: true,
    open_recently_completed: true,
    open_completed: true,
  }

  const [state, setState] = useState(initialState);

  const { modal_open,
          all_tasks,
          important_tasks,
          past_due_tasks,
          today_tasks,
          tomorrow_tasks,
          this_week_tasks,
          future_tasks,
          recently_completed_tasks,
          completed_tasks,
          editTask,
          setImportant,
          assignAndAccept,
          setCompleted,
          deleteTask,
          isRestricted,
        } = props;
  const { open_important,
          open_past_due,
          open_today,
          open_future,
          open_this_week,
          open_tomorrow,
          open_completed,
          open_recently_completed} = state;

  const handleOpenClose = (name) => {
    setState({ ...state, [name]: !state[name] });
  }

  return (<>
    <ToastContainer />
    {!isRestricted && <div className="pe-3">
      <div className="quick-task-container">
        <QuickTaskForm
          assign_to_options={props.assign_to_options}
          user_id={props.user_id}
          dateTimeFormat={props.dateTimeFormat}
          timezone={props.timezone}
          btn_name={"Add Task"}
          handleOpenClose={handleOpenClose}
          newTask={props.newTask}
          due_date_options={props.due_date_options}
          default_due_time = {props.default_due_time}
          createTask={props.createTask}
        />
      </div>
    </div>}
    <div id="container_view_dashboard_tasks_header_container">
      <div id="container_view_dashboard_tasks_header">
        <div className="align-vertical-middle justify-content-start date-item">
          <b>DUE DATE</b>
        </div>
        <div className="align-vertical-middle justify-content-start assignee-item">
          <b>ASSIGNED TO</b>
        </div>

      </div>
    </div>
    <div id="container_view_dashboard_tasks">
      {all_tasks && all_tasks.length === 0 && <h6><i>No Tasks</i></h6>}
      {important_tasks.length > 0 &&
        <TaskListItem
          name={"Important"}
          open={open_important}
          open_key={"open_important"}
          handleOpenClose={handleOpenClose}
          tasks={important_tasks}
          color={"#f4e7e8"}
          checkbox_color={"#91121E"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {past_due_tasks.length > 0 &&
        <TaskListItem
          name={"Past Due"}
          open={open_past_due}
          open_key={"open_past_due"}
          handleOpenClose={handleOpenClose}
          tasks={past_due_tasks}
          color={"#fdede9"}
          checkbox_color={"#EC4E20"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {today_tasks.length > 0 &&
        <TaskListItem
          name={"Today"}
          open={open_today}
          open_key={"open_today"}
          handleOpenClose={handleOpenClose}
          tasks={today_tasks}
          color={"#e8f2e9"}
          checkbox_color={"#157F1F"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {tomorrow_tasks.length > 0 &&
        <TaskListItem
          name={"Tomorrow"}
          open={open_tomorrow}
          open_key={"open_tomorrow"}
          handleOpenClose={handleOpenClose}
          tasks={tomorrow_tasks}
          color={"#e6edf4"}
          checkbox_color={"#094D92"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {this_week_tasks.length > 0 &&
        <TaskListItem
          name={"This Week"}
          open={open_this_week}
          open_key={"open_this_week"}
          handleOpenClose={handleOpenClose}
          tasks={this_week_tasks}
          color={"#e6edf4"}
          checkbox_color={"#094D92"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {future_tasks.length > 0 &&
        <TaskListItem
          name={"Future"}
          open={open_future}
          open_key={"open_future"}
          handleOpenClose={handleOpenClose}
          tasks={future_tasks}
          color={"#e6edf4"}
          checkbox_color={"#094D92"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {recently_completed_tasks.length > 0 &&
        <TaskListItem
          name={"Recently Completed"}
          open={open_recently_completed}
          open_key={"open_recently_completed"}
          handleOpenClose={handleOpenClose}
          tasks={recently_completed_tasks}
          color={"#e6edf4"}
          checkbox_color={"#094D92"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {completed_tasks.length > 0 &&
        <TaskListItem
          name={"Completed"}
          open={open_completed}
          open_key={"open_completed"}
          handleOpenClose={handleOpenClose}
          tasks={completed_tasks}
          color={"#e6edf4"}
          checkbox_color={"#094D92"}
          editTask={editTask}
          deleteTask={deleteTask}
          setImportant={setImportant}
          setCompleted={setCompleted}
          assignAndAccept={assignAndAccept}
          isRestricted={isRestricted}
          userId={props.user_id}
          taskId={props.taskId}
        />
      }
      {modal_open && <TaskModalContainer/>}
    </div>
  </>);
}

export default TaskDashboardBody
