import { connect } from 'react-redux'
import Body from '../components/Body'
import { switchView } from '../actions'


const mapStateToProps = (state) => ({
  view: state.stock_order.view,
  modal_open: state.stock_order.modal_open,
  modal_place_open: state.stock_order.modal_place_open,
  modal_delivered_open: state.stock_order.modal_delivered_open,
  modal_logos_open: state.stock_order.modal_logos_open,
  modal_finalize_open: state.stock_order.modal_finalize_open,
  modal_split_open: state.stock_order.modal_split_open,
  modal_cancel_open: state.stock_order.modal_cancel_open,
  modal_filter_open: state.stock_order.modal_filter_open,
  modal_ship_details_open: state.stock_order.modal_ship_details_open,
  modal_list_view_optional_fields: state.stock_order.modal_list_view_optional_fields
})

const mapDispatchToProps = (dispatch) => ({
  switchView: view => dispatch(switchView(view)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)